import { IVendorLocationDto } from "api/contacts-api-dto-interfaces";
import { IVendorDtoV1Response } from "api/GeneratedClients/ContactsClient";
import { isNullOrWhitespace } from "core";
import { isEqual } from "lodash-es";
import { actions as contactActions } from "modules/contacts";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
interface UseSaveVendorDetailsProps {
  setShowErrors: (value: boolean) => void;
}

export function useSaveVendorDetails({
  setShowErrors
}: UseSaveVendorDetailsProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSaveChanges = (
    vendor: IVendorDtoV1Response,
    unsavedVendorLocation: IVendorLocationDto | undefined = undefined
  ) => {
    let updatedVendor: IVendorDtoV1Response;
    if (unsavedVendorLocation) {
      updatedVendor = includeUnsavedVendorLocation(
        vendor,
        unsavedVendorLocation
      );
    } else {
      updatedVendor = vendor;
    }

    if (!validateLocalVendor(updatedVendor)) return;
    if (vendor === undefined) return;
    dispatch(
      contactActions.updateVendor.request({
        vendor: updatedVendor,
        history: history
      })
    );
  };

  const validateLocalVendor = (vendor: IVendorDtoV1Response) => {
    if (vendor) {
      if (isNullOrWhitespace(vendor.code)) {
        setShowErrors(true);
        return false;
      }
      return true;
    }
    return false;
  };

  return { handleSaveChanges };
}

const includeUnsavedVendorLocation = (
  vendor: IVendorDtoV1Response,
  unsavedLocation: IVendorLocationDto
): IVendorDtoV1Response => {
  let updatedVendor: IVendorDtoV1Response = vendor;
  const unsavedLocationInRedux = vendor.locations?.find(
    loc => loc.id === unsavedLocation.id
  );
  if (
    unsavedLocationInRedux &&
    !isNullOrWhitespace(unsavedLocation.nickname) &&
    !isEqual(unsavedLocation, unsavedLocationInRedux)
  ) {
    const updatedLocations = vendor.locations?.map(loc => {
      if (loc.id === unsavedLocation.id) {
        return unsavedLocation;
      }
      return loc;
    });
    updatedVendor = { ...vendor, locations: updatedLocations };
  }
  const filteredNullNicknameLocations = updatedVendor.locations?.filter(
    loc => !isNullOrWhitespace(loc.nickname)
  );
  return { ...updatedVendor, locations: filteredNullNicknameLocations };
};
