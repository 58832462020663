import {
  IVendorDtoV1Response,
  CompanyTypeDto
} from "api/GeneratedClients/ContactsClient";
import { isValidString } from "core";
import { ConcreteColors, ControlLabel, FormGroup } from "hcss-components";
import { strings } from "localization";
import { sortBy } from "lodash-es";
import { selectors as contactsSelectors } from "modules/contacts";
import React from "react";
import { useSelector } from "react-redux";
import Creatable from "react-select/creatable";
import styled from "styled-components";

interface CompanyTypeProps {
  vendor: IVendorDtoV1Response;
  setVendorType: (e: any) => void;
  showErrors: boolean;
}

interface CompanyTypeOption {
  label: string;
  description: string;
  value: string;
  __isNew__?: boolean;
}

const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const VendorCompanyType = ({
  vendor,
  setVendorType
}: CompanyTypeProps) => {
  const companyTypes = useSelector(contactsSelectors.getCompanyTypes);
  let options = companyTypes.map(ct => ({
    value: ct.id ?? "",
    description: ct.description ?? "",
    label: ct.code ?? ""
  }));
  options = sortBy(options, opt => opt.label.toLocaleLowerCase());

  const updateCompanyType = (opt: any) => {
    if (!opt) {
      setVendorType(null);
    } else {
      if (opt && !opt.label) {
        return;
      }
      // all codes get capitalized when created by the Contacts API
      // so we change it here for any newly created scopes by the user
      opt.label = opt.label.toUpperCase();
      //value will be the guid for an existing product OR the newly created value
      // one way to check for newly created values will be to verify id === description
      opt.value = opt.value.toLowerCase();

      const companyType: CompanyTypeDto = new CompanyTypeDto({
        id: opt.__isNew__ ? EMPTY_GUID : opt.value,
        code: opt.label,
        description: opt.__isNew__ ? opt.value : opt.description // created options won't have a description property
      });

      setVendorType(companyType);
    }
  };

  const isValidNewOption = (
    inputValue: string,
    value: any,
    options: any
  ): boolean => {
    const optionLabels = options?.map(o => o.label.toUpperCase()) ?? [];
    return !optionLabels.includes(inputValue.toUpperCase());
  };

  const formatCreateLabel = (inputValue: string) =>
    isValidString(inputValue)
      ? `Create "${inputValue.toUpperCase()}"`
      : "To add a new option: type a value, press Enter";

  return (
    <>
      <CompanyType>
        <div className="CompanyType">
          <div>
            <FormGroup>
              <ControlLabel>
                {strings.contact.mainInfo.companyType}
              </ControlLabel>
              <Creatable
                classNamePrefix="react-select"
                className="react-select-container"
                options={options}
                defaultValue={{
                  value: vendor.type?.code,
                  label: vendor.type?.code
                }}
                value={{
                  value: vendor.type?.code,
                  label: vendor.type?.code
                }}
                onChange={updateCompanyType}
                isValidNewOption={isValidNewOption}
                formatCreateLabel={formatCreateLabel}
                isClearable={vendor.type?.code}
                styles={CustomStyle}
              />
            </FormGroup>
          </div>
        </div>
      </CompanyType>
    </>
  );
};

const CompanyType = styled.div`
  .form-group[required] label:after {
    content: " *";
    color: ${ConcreteColors.red200};
  }
  div.scopes {
    grid-area: scopes;
  }
`;

const CustomStyle = {
  option: (base, state) => {
    let color = "";
    let backgroundColor = "";

    if (state.data.label === state.selectProps.value.label) {
      color = "white";
      backgroundColor = "#2684FF";
    } else if (state.isFocused) {
      backgroundColor = "#DEEBFF";
    }

    return {
      ...base,
      color,
      backgroundColor
    };
  }
};
