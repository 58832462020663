import React, { useState } from "react";
import styled from "styled-components";
import { strings } from "localization";
import {
  ControlLabel,
  FormGroup,
  FormControl,
  ConcreteColors,
  HelpBlock,
  Checkbox
} from "hcss-components";
import numeral from "numeral";
import { IVendorDtoV1Response } from "api/GeneratedClients/ContactsClient";
import { isNullOrWhitespace } from "core";
import { VendorCompanyType } from "./vendor-company-type";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

interface MainInfoProps {
  vendor: IVendorDtoV1Response;
  // TODO: Specify a different type for any
  setVendor: (e: any) => void;
  showErrors: boolean;
}

export const VendorMainInfo = ({
  vendor,
  setVendor,
  showErrors
}: MainInfoProps) => {
  // TODO: Specify a different type for any
  const [bondRateString, setBondRateString] = useState(
    vendor.bondRate?.toString() ?? ""
  );
  const handleChange = (e: any) => {
    e.persist();

    const name = e.currentTarget.name;

    switch (name) {
      case "code": {
        const code = e.currentTarget.value;
        setVendor({ ...vendor, code });
        break;
      }
      case "vendorName": {
        const name = e.currentTarget.value;
        setVendor({ ...vendor, name });
        break;
      }
      // Are we keeping track of the email field for a vendor?
      case "email": {
        const email = e.currentTarget.value;
        setVendor({ ...vendor, email });
        break;
      }
      case "webAddress": {
        const webAddress = e.currentTarget.value;
        setVendor({ ...vendor, webAddress });
        break;
      }
      case "note": {
        const note = e.currentTarget.value;
        setVendor({ ...vendor, note });
        break;
      }
      case "isBonded": {
        const isBonded = e.currentTarget.checked;
        setVendor({ ...vendor, isBonded });
        break;
      }
      case "bondRate": {
        const valueString = e.currentTarget.value as string;
        let bondRate = numeral(valueString).value();
        if (!isNaN(bondRate)) {
          if (bondRate === null) {
            bondRate = 0;
          }
          // In HeavyBid, they accept value from 9999.99 to -999.99
          const isValidBondRate = bondRate >= -999.99 && bondRate <= 9999.99;
          if (isValidBondRate) {
            setVendor({ ...vendor, bondRate });
            setBondRateString(valueString);
          } else {
            bondRate = numeral(
              valueString.replace(",", "").substring(0, 4)
            ).value();
            setVendor({ ...vendor, bondRate });
            setBondRateString("-" + valueString.slice(1).substring(0, 4));
          }
        }
        break;
      }
      case "accountingCode": {
        const accountingCode = e.currentTarget.value;
        setVendor({ ...vendor, accountingCode });
        break;
      }
    }
  };

  const updateCompanyType = (companyType: any) => {
    setVendor({
      ...vendor,
      type: companyType
    });
  };

  const bondRateMask = createNumberMask({
    allowDecimal: true,
    allowNegative: true,
    prefix: "",
    decimalLimit: 2,
    integerLimit: 4,
    allowLeadingZeroes: true
  });
  return (
    <MainInfo>
      <div className="mainInfo">
        <div className="code">
          <FormGroup
            required
            validationState={
              showErrors && isNullOrWhitespace(vendor.code) ? "error" : null
            }
          >
            <ControlLabel>{strings.contact.mainInfo.code}</ControlLabel>
            <FormControl
              type="text"
              name="code"
              style={{ textTransform: "uppercase" }}
              autoComplete="none"
              value={vendor.code}
              onChange={e => handleChange(e)}
            />
            {showErrors && isNullOrWhitespace(vendor.code) && (
              <HelpBlock>Code is required.</HelpBlock>
            )}
          </FormGroup>
        </div>
        <div className="vendorName">
          <FormGroup>
            <ControlLabel>{strings.contact.mainInfo.name}</ControlLabel>
            <FormControl
              type="text"
              name="vendorName"
              autoComplete="none"
              value={vendor.name}
              onChange={e => handleChange(e)}
            />
          </FormGroup>
        </div>
        <div className="webAddress">
          <FormGroup>
            <ControlLabel>{strings.contact.mainInfo.webAddress}</ControlLabel>
            <FormControl
              type="text"
              name="webAddress"
              autoComplete="none"
              value={vendor.webAddress}
              onChange={e => handleChange(e)}
            />
          </FormGroup>
        </div>
        <div className="companyType">
          <VendorCompanyType
            vendor={vendor}
            setVendorType={updateCompanyType}
            showErrors={showErrors}
          />
        </div>
        <div className="note">
          <FormGroup>
            <ControlLabel>{strings.contact.detailInfo.note}</ControlLabel>
            <textarea
              className="form-control"
              name="note"
              style={{ minHeight: "125px", resize: "vertical" }}
              value={vendor.note}
              onChange={e => handleChange(e)}
            />
          </FormGroup>
        </div>
        <div className="isBonded">
          <FormGroup>
            <ControlLabel style={{ display: "block" }}>
              {strings.contact.detailInfo.isBonded}
            </ControlLabel>
            <Checkbox
              label={strings.contact.detailInfo.isBonded}
              name="isBonded"
              checked={vendor.isBonded}
              onChange={e => handleChange(e)}
            />
          </FormGroup>
        </div>
        <div className="bondRate">
          <FormGroup>
            <ControlLabel>{strings.contact.detailInfo.bondRate}</ControlLabel>
            <MaskedInput
              name="bondRate"
              className="form-control"
              value={bondRateString}
              onChange={e => handleChange(e)}
              mask={bondRateMask}
              style={{ textAlign: "left" }}
            />
          </FormGroup>
        </div>
        <div className="accountingCode">
          <FormGroup>
            <ControlLabel>
              {strings.contact.detailInfo.accountingCode}
            </ControlLabel>
            <FormControl
              type="text"
              maxLength={16}
              name="accountingCode"
              autoComplete="none"
              value={vendor.accountingCode}
              onChange={e => handleChange(e)}
            />
          </FormGroup>
        </div>
      </div>
    </MainInfo>
  );
};

const MainInfo = styled.div`
  div.mainInfo {
    display: grid;
    background-color: #ffffff;
    grid-template-columns: 30% 30% 35%;
    column-gap: 11px;
    row-gap: 10px;
    grid-template-areas:
      "code vendorName vendorName"
      "webAddress note note"
      "companyType note note"
      "isBonded bondRate accountingCode";
    align-items: center;
  }

  .form-group[required] label:after {
    content: " *";
    color: ${ConcreteColors.red200};
  }
  div.code {
    grid-area: code;
  }
  div.vendorName {
    grid-area: vendorName;
  }
  div.webAddress {
    grid-area: webAddress;
  }
  div.companyType {
    grid-area: companyType;
  }
  div.note {
    grid-area: note;
  }
  div.isBonded {
    grid-area: isBonded;
  }
  div.bondRate {
    grid-area: bondRate;
  }
  div.accountingCode {
    grid-area: accountingCode;
  }
`;
